module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.3_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-3P7MT2NZPX"],"gtagConfig":{}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.3_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PLCO Homepage","short_name":"PLCO Homepage","description":"PLCO Homepage","lang":"ko","start_url":"./","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/images/favicon.png","icons":[{"src":"/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"localize":[{"start_url":"./en/","lang":"en","name":"PLCO Homepage","description":"PLCO Homepage","short_name":"PLCO Homepage"},{"start_url":"./ja/","lang":"ja","name":"PLCO Homepage","description":"PLCO Homepage","short_name":"PLCO Homepage"},{"start_url":"./vi/","lang":"vi","name":"PLCO Homepage","description":"PLCO Homepage","short_name":"PLCO Homepage"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"17bf1423f4c42c95188fc426f89df0b6"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-offline@6.13.1_gatsby@5.13.3_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-intl@5.10.0_patch_hash=hhv4fvsm6h5yk4qhyvfky3cnly_react@18.2.0_typescript@5.4.2/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/vercel/path0/packages/plco-coach-homepage/src/messages","languages":["ko","en","ja","vi"],"defaultLanguage":"ko","redirect":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.3_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.4.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
